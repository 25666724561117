<template>
  <div class="container">
    <BaseHeader title="E-mails" :navigation="navigation"></BaseHeader>

    <div style="max-width: 900px; margin: 75px auto" v-if="!loading">
      <div class="containerTotal">
        <div class="total">
          <span>Total de e-mails mensais</span>
          <h1>{{ emailLimit | formatNumber }}</h1>
          <ul class="mt-5">
            <li>
              <p>Últimos 90 dias</p>
              <div>
                <h3>{{ emails_mensal90 | formatNumber }}</h3>
                <span>Entregues</span>
                <img
                  v-if="emails_mensal90 > 0"
                  src="@/assets/icons/icon-trend-up.svg"
                  alt="UP"
                />
                <img
                  v-if="emails_mensal90 == 0"
                  src="@/assets/icons/icon-equal.svg"
                  alt="Equal"
                />
              </div>
            </li>
            <li>
              <p>Últimos 60 dias</p>
              <div>
                <h3>{{ emails_mensal60 | formatNumber }}</h3>
                <span>Entregues</span>
                <img
                  v-if="emails_mensal60 < emails_mensal90"
                  src="@/assets/icons/icon-trend-down.svg"
                  alt="Down"
                />
                <img
                  v-if="emails_mensal60 > emails_mensal90"
                  src="@/assets/icons/icon-trend-up.svg"
                  alt="UP"
                />
                <img
                  v-if="emails_mensal60 == emails_mensal90"
                  src="@/assets/icons/icon-equal.svg"
                  alt="Equal"
                />
              </div>
            </li>
            <li>
              <p>Últimos 30 dias</p>
              <div>
                <h3>{{ emails_mensal30 | formatNumber }}</h3>
                <span>Entregues</span>
                <img
                  v-if="emails_mensal30 < emails_mensal60"
                  src="@/assets/icons/icon-trend-down.svg"
                  alt="Down"
                />
                <img
                  v-if="emails_mensal30 > emails_mensal60"
                  src="@/assets/icons/icon-trend-up.svg"
                  alt="UP"
                />
                <img
                  v-if="emails_mensal30 == emails_mensal60"
                  src="@/assets/icons/icon-equal.svg"
                  alt="Equal"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="line"></div>
      <div>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 30px;
          "
        >
          <div style="display: flex; gap: 15px; align-items: center">
            <p class="title-brodcast">Precisando de mais envios?</p>
            <img class="valid" src="@/assets/img/icons/shop.svg" />
          </div>

          <img
            width="17px"
            height="17px"
            src="@/assets/img/icons/help-circle.svg"
            alt="icon"
            v-b-tooltip.hover
            title="Após a compra, os envios são geralmente liberados em cerca de 15 minutos."
          />
        </div>
        <div
          style="
            display: flex;
            justify-content: center;
            gap: 30px;
            align-items: center;
          "
        >
          <div class="card mb-4" v-for="(card, index) in cards" :key="index">
            <div class="infos">
              <span>Broadcast e Sequência de e-mails</span>
              <h1>R$ {{ card.price }}</h1>
              <span>Mensal</span>
              <div class="line2"></div>
              <span>{{ card.shots }} mil envios</span>
              <div class="line2"></div>
              <span>Cartão, Pix e Boleto</span>
              <div class="line2"></div>
              <span>Receba em até 15 minutos</span>
              <BaseButton
                @click="contract(card)"
                style="bottom: 0; width: 90% !important"
                class="mt-5"
                variant="primary"
                >Adquirir</BaseButton
              >
            </div>
            <div class="eclipse-1"></div>
            <div class="eclipse-2"></div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="loading" style="max-width: 900px; margin: 75px auto">
      <div class="containerTotal">
        <b-skeleton
          width="100%"
          height="195px"
          style="border-radius: 10px"
        ></b-skeleton>
      </div>

      <b-skeleton
        width="340px"
        height="30px"
        style="border-radius: 10px; margin: 145px 0 20px 0"
      ></b-skeleton>
      <div style="display: flex; gap: 30px; align-items: center">
        <b-skeleton
          width="50%"
          height="640px"
          style="border-radius: 10px"
        ></b-skeleton>

        <b-skeleton
          width="50%"
          height="640px"
          style="border-radius: 10px"
        ></b-skeleton>
      </div>
    </div>
  </div>
</template>

<script>
//
import EmailTotalGraphService from "@/services/resources/EmailTotalGraphService";
const serviceEmailTotal = EmailTotalGraphService.build();
//
import contractPlano from "@/services/resources/contractPlan";
const contractPlan = contractPlano.build();
//
import moment from "moment";

export default {
  data() {
    return {
      loading: false,
      emails_mensal: [],
      emails_mensal90: "",
      emails_mensal60: "",
      emails_mensal30: "",

      cards: [
        {
          price: 50,
          shots: 100,
        },
        {
          price: 100,
          shots: 200,
        },
      ],

      startDate30: moment()
        .startOf("hour")
        .subtract(730, "hour")
        .format("YYYY-MM-DD"),
      endDate30: moment()
        .startOf("hour")
        .subtract(1, "hour")
        .format("YYYY-MM-DD"),

      startDate60: moment()
        .startOf("hour")
        .subtract(1460, "hour")
        .format("YYYY-MM-DD"),
      endDate60: moment()
        .startOf("hour")
        .subtract(1, "hour")
        .format("YYYY-MM-DD"),

      startDate90: moment()
        .startOf("hour")
        .subtract(2190, "hour")
        .format("YYYY-MM-DD"),
      endDate90: moment()
        .startOf("hour")
        .subtract(1, "hour")
        .format("YYYY-MM-DD"),
      navigation: [
        { link: "Aplicativos", to: "/dynamicRoute/apps" },
        { link: "Broadcast", to: "/dynamicRoute/apps/broadcast" },
        { link: "E-mails", to: this.$route.fullPath },
      ],
    };
  },

  filters: {
    formatNumber(number) {
      if (typeof number !== "undefined" && number) {
        return number.toLocaleString("pt-BR", {
          maximumFractionDigits: 0,
          useGrouping: true,
        });
      }
      return "0";
    },
  },

  methods: {
    async getEmailMensal(startDate, endDate) {
      try {
        const resp = await serviceEmailTotal.read(
          `?from_date=${startDate}&to_date=${endDate}`
        );
        const emails = resp.data.email_delivereds.total_count;
        
        if (emails !== null) {
          return emails;
        } else {
          return 0;
        }
      } catch (err) {
        return 0;
      }
    },

    async fetchAndSetEmails() {
      this.emails_mensal30 = await this.getEmailMensal(
        this.startDate30,
        this.endDate30
      );
      this.emails_mensal60 = await this.getEmailMensal(
        this.startDate60,
        this.endDate60
      );
      this.emails_mensal90 = await this.getEmailMensal(
        this.startDate90,
        this.endDate90
      );
      this.loading = false;
    },
    contract(data) {
      contractPlan
        .search()
        .then((r) => {
          let name = `${r.first_name} ${r.last_name}`;
          let phone = r.phone;
          let email = r.email;
          let document = r.document;
          let url = `https://pay.greenn.com.br/${
            data.price == 50 ? "30419" : "30421"
          }?&fn=${name}&ph=${phone}&em=${email}&document=${document}&force=true`;

          window.open(url, "_blank");
        })
        .catch((err) => {});
    },
  },
  mounted() {
    this.fetchAndSetEmails();
  },
  computed: {
    emailLimit(){
      const additional = this.$store.getters.recursos.nenvios ? this.$store.getters.recursos.nenvios : 0;
      return (this.$store.getters.recursos.nleads * this.$store.getters.recursos.nmultiplicationemails) + additional;
    }
  }
};
</script>

<style scoped lang="scss">
.line {
  height: 1px;
  background-color: var(--white-medium);
  margin: 70px auto;
  width: 100%;
}
.title-brodcast {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: var(--gray01);
  margin-bottom: 0;
}

.containerTotal {
  display: flex;
  align-items: center;
  justify-content: center;
  .total {
    width: 100%;
    display: flex;
    flex-direction: column;
    span {
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      color: var(--gray04);
      line-height: 32px;
    }

    h1 {
      font-weight: 600;
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0 !important;
      li {
        width: 170px;
        p {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 5px;
          color: var(--gray04);
        }

        div {
          display: flex;
          align-items: center;
          gap: 10px;

          span {
            font-size: 12px;
            font-weight: 500;
          }

          h3 {
            font-weight: 600;
          }
        }
      }
    }
  }
}

.card {
  width: 100%;
  height: 640px;
  border-radius: 10px;
  border: 2px solid var(--white-medium);
  overflow: hidden;
  .infos {
    position: absolute;
    z-index: 99;
    width: 100%;
    padding: 50px 10px;
    height: 640px;
    background: rgba(255, 255, 255, 0.3);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    span {
      font-weight: 500;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      color: var(--gray01);
      line-height: 57px;
    }

    h1 {
      font-weight: 800;
    }

    .line2 {
      height: 1px;
      width: 300px;
      margin: auto;
      background-color: #14141417;
      padding: 0;
      margin: 5px 0px;
    }
  }

  .eclipse-1 {
    width: 150px;
    height: 150px;
    margin-top: 100px;
    position: absolute;
    border-radius: 9999px;
    bottom: 0;
    z-index: 0;
    left: 0;
    filter: blur(85px);
    transition: all 0.3s linear;

    background-color: var(--greenn);
  }
  .eclipse-2 {
    width: 150px;
    height: 150px;
    position: absolute;
    border-radius: 9999px;
    bottom: 250px;
    z-index: 0;
    right: 0;
    filter: blur(65px);
    transition: all 0.3s linear;
    background-color: var(--greenn-light);
  }

  &:hover {
    .eclipse-1 {
      transition: all 0.3s linear;
      left: 30px;
      bottom: 30px;
      width: 230px;
      height: 230px;
    }
    .eclipse-2 {
      transition: all 0.3s linear;
      bottom: 350px;
      right: 20px;
    }
  }
}
section {
  .grafic {
    width: 550px;
    height: 40px;
    overflow: hidden;
    border-radius: 5px;
    background-color: var(--white-medium);

    .percentageGrafic {
      width: 30%;
      height: 40px;
      background-color: var(--greenn);
    }
  }
  .content-leads-actives {
    margin-top: -40px;
    div {
      margin-top: 50px;
      display: flex;
      .text-number-total {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        color: var(--gray05);
        line-height: 57px;
      }
      .text-number-active {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 150%;
        letter-spacing: 0.05em;
        color: var(--greenn);
      }
    }
  }

  .text-active {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--gray05);
  }
}
</style>
